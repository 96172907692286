import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
  Table,
  message,
} from 'antd';
import MemberSelect from './member-select';
import ProductSelect from './product-select';
import PaymentSelect from './payment-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OmsOrder,
  OrderGenerateForMemberDto,
  StoreConfirmOrder,
} from 'types/oms';
import { omsGenerateConfirmOrder, omsGenerateForMember } from 'apis/oms';
import { useToggle } from 'react-use';
import StepItem from 'components/step-item';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import PORTAL_ORIGIN from 'utils/getPortalOrigin';
import splitAmount from 'utils/splitAmount';
import {
  CURRENCY_MAP,
  CURRENCY_OPTION_LIST,
  findLabelByValue,
  ORDER_STATUS_MAP,
  SHOP_MAP,
  DELIVERY_TYPE,
} from 'commons/options';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';
import { Trans } from 'react-i18next';
import CreatedSelect from './created-select';
import useIsMobile from 'commons/hooks/useIsMobile';

const MultiplePaySet = ({
  payAmount,
  currency,
  onFinish,
  onClear,
}: {
  currency: string;
  payAmount: number;
  onFinish: (list: number[]) => void;
  onClear: () => void;
}) => {
  return (
    <div>
      <Form
        layout="horizontal"
        labelCol={{ span: 8 }}
        initialValues={{
          count: 2,
        }}
        onFinish={({
          count,
          firstAmount,
        }: {
          count: number;
          firstAmount: number;
        }) => {
          onFinish(splitAmount(payAmount, count, firstAmount));
        }}
      >
        <Form.Item
          label={i18n.t(LOCALS.multiple_pay_count) || '支付笔数（2-5笔）'}
          name="count"
          required
          rules={[{ required: true }]}
        >
          <InputNumber
            size="large"
            className="w-full"
            min={2}
            max={5}
            step={1}
          />
        </Form.Item>
        <Form.Item
          label={i18n.t(LOCALS.first_amount) || '首笔金额'}
          name="firstAmount"
          required
          rules={[{ required: true }]}
        >
          <InputNumber
            size="large"
            className="w-full"
            suffix={findLabelByValue(currency, CURRENCY_OPTION_LIST)}
          />
        </Form.Item>
        <div className="flex justify-end">
          <Button onClick={onClear} className="mr-2">
            {i18n.t(LOCALS.reset) || '清空'}
          </Button>
          <Button type="primary" htmlType="submit">
            {i18n.t(LOCALS.submit) || '生成'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const OrderCreateV2 = () => {
  const [omsOrderCreatePayload, setOmsOrderCreatePayload] =
    useState<OrderGenerateForMemberDto>({
      memberId: 0,
      productIdList: [],
      useIntegration: 0,
      promotionAmount: 0,
      couponCode: '',
      createdFrom: SHOP_MAP.WEBSITE,
      orderStatus: undefined,
      note: undefined,
      deliveryType: DELIVERY_TYPE.DELIVERY,
    });

  const [confirmOrderInfo, setConfirmOrderInfo] = useState<StoreConfirmOrder>();
  const [resultLoading, toggleResultLoading] = useToggle(false);
  const [modelOpen, toggleModelOpen] = useToggle(false);
  const [saveLoading, toggleSaveLoading] = useToggle(false);
  const [orderData, setOrderData] =
    useState<Pick<OmsOrder, 'id' | 'orderSn'>>();
  const [multiPayOpen, toggleMultiPayOpen] = useToggle(false);
  const isMobile = useIsMobile();

  // 币种
  const currency = useMemo(() => {
    if (
      confirmOrderInfo?.omsOrderItems.length &&
      confirmOrderInfo?.omsOrderItems[0].actualCurrency
    ) {
      return confirmOrderInfo?.omsOrderItems[0].actualCurrency;
    }

    return CURRENCY_MAP.JPY;
  }, [confirmOrderInfo]);

  useEffect(() => {
    if (!omsOrderCreatePayload.productIdList.length) return;

    toggleResultLoading();
    omsGenerateConfirmOrder({
      memberId: omsOrderCreatePayload.memberId,
      productIdList: omsOrderCreatePayload.productIdList,
      useIntegration: omsOrderCreatePayload.useIntegration,
      promotionAmount: omsOrderCreatePayload.promotionAmount,
      couponCode: omsOrderCreatePayload.couponCode,
      receiveAddressId: omsOrderCreatePayload.receiveAddressId,
      orderStatus: omsOrderCreatePayload.orderStatus,
      deliveryType: omsOrderCreatePayload.deliveryType,
    })
      .then((data) => {
        setConfirmOrderInfo(data);
      })
      .catch(() => {
        setConfirmOrderInfo(undefined);
      })
      .finally(() => toggleResultLoading());
  }, [
    omsOrderCreatePayload.memberId,
    omsOrderCreatePayload.productIdList,
    omsOrderCreatePayload.useIntegration,
    omsOrderCreatePayload.promotionAmount,
    omsOrderCreatePayload.couponCode,
    omsOrderCreatePayload.receiveAddressId,
    omsOrderCreatePayload.orderStatus,
    omsOrderCreatePayload.deliveryType,
    toggleResultLoading,
  ]);

  const setPayload = useCallback(
    (payload: Partial<OrderGenerateForMemberDto>) => {
      setOmsOrderCreatePayload((obj) => {
        return { ...obj, ...payload };
      });
    },
    []
  );

  // 创建订单
  const handleCreateOrder = useCallback(() => {
    const {
      memberId,
      productIdList,
      createdFrom,
      receiveAddressId,
      orderStatus,
      deliveryType,
    } = omsOrderCreatePayload;

    if (!memberId) {
      message.warning(`${i18n.t('please_select')}${i18n.t('member')}`);
      return;
    }
    if (
      deliveryType === DELIVERY_TYPE.DELIVERY &&
      !receiveAddressId &&
      orderStatus === ORDER_STATUS_MAP.TO_BE_PAID
    ) {
      message.warning(i18n.t('please_select_a_shipping_address'));
      return;
    }

    if (productIdList?.length === 0) {
      message.warning(`${i18n.t('select_items')}`);
      return;
    }

    if (createdFrom === undefined) {
      message.warning(i18n.t('enter_order_source'));
      return;
    }

    toggleSaveLoading();
    omsGenerateForMember(omsOrderCreatePayload)
      .then((data) => {
        setOrderData(data.omsOrder);
      })
      .catch(() => {})
      .finally(() => {
        toggleSaveLoading();
      });
  }, [omsOrderCreatePayload, toggleSaveLoading]);

  return (
    <div className="-m-2 md:-m-4">
      <StepItem
        index={1}
        title={i18n.t(LOCALS.member_details) || '会员选择'}
        isCompleted={!!omsOrderCreatePayload.memberId}
      >
        <MemberSelect setPayload={setPayload} currency={currency}/>
      </StepItem>

      <div className="w-full h-3 bg-gray-100" />

      <StepItem
        index={2}
        title={i18n.t(LOCALS.product_selection) || '商品选择'}
        isCompleted={!!omsOrderCreatePayload.productIdList.length}
      >
        <ProductSelect setPayload={setPayload} />
      </StepItem>

      <div className="w-full h-3 bg-gray-100"></div>

      <StepItem
        index={3}
        title={i18n.t(LOCALS.payment_settings) || '支付设置'}
        isCompleted={false}
      >
        <PaymentSelect
          setPayload={setPayload}
          confirmOrderInfo={confirmOrderInfo}
          currency={currency}
          orderStatus={omsOrderCreatePayload.orderStatus}
          receiveAddressId={omsOrderCreatePayload.receiveAddressId}
          useIntegration={omsOrderCreatePayload.useIntegration}
        />
      </StepItem>

      <div className="w-full h-3 bg-gray-100"></div>

      <StepItem index={4} title={i18n.t('order_source')} isCompleted={false}>
        <CreatedSelect
          setPayload={setPayload}
          createdFrom={omsOrderCreatePayload.createdFrom}
        />

        <div className="px-4 mb-4">
          <Input.TextArea
            value={omsOrderCreatePayload.note}
            onChange={(e) => {
              setPayload({ note: e.target.value });
            }}
            size="large"
            rows={5}
            placeholder={i18n.t(LOCALS.please_enter_remark) || ''}
            maxLength={250}
          ></Input.TextArea>
        </div>
      </StepItem>

      <div className="p-4 pt-0">
        {omsOrderCreatePayload.multiplePaySet && (
          <Table
            size="small"
            className="mb-4"
            pagination={false}
            rowKey="sortId"
            dataSource={omsOrderCreatePayload.multiplePaySet}
            columns={[
              {
                title: <Trans i18nKey={LOCALS.sortId} />,
                dataIndex: 'sortId',
              },
              {
                title: <Trans i18nKey={LOCALS.pay_amount} />,
                dataIndex: 'needPayAmount',
                render(needPayAmount: number) {
                  return (
                    <span>
                      {needPayAmount.toLocaleString()}（
                      {findLabelByValue(currency, CURRENCY_OPTION_LIST)}）
                    </span>
                  );
                },
              },
            ]}
          ></Table>
        )}

        <div className="text-right mb-4">
          {confirmOrderInfo && (
            <Spin spinning={resultLoading}>
              <div className="mb-2 text-base">
                <span className="font-bold">
                  <Trans i18nKey={LOCALS.freight} />：
                </span>
                <span>
                  {findLabelByValue(currency, CURRENCY_OPTION_LIST)}
                  {confirmOrderInfo.omsOrder &&
                  confirmOrderInfo.omsOrder.freightAmountActualCurrency
                    ? `${
                        confirmOrderInfo.omsOrder.freightAmountActualCurrency.toLocaleString() ||
                        0
                      }`
                    : 0}
                </span>
              </div>
              <div className="mb-2 text-base">
                <span className="font-bold">
                  <Trans i18nKey={LOCALS.pay_amount} />：
                </span>
                <span>
                  {findLabelByValue(currency, CURRENCY_OPTION_LIST)}
                  {` ${confirmOrderInfo.omsOrder.payAmountActualCurrency.toLocaleString()}`}
                </span>
              </div>
              <div className="mb-2 text-base">
                <span className="font-bold">
                  {i18n.t(LOCALS.convert_to_jpy) || '折算日币'}：
                </span>
                <span>
                  {findLabelByValue('JPY', CURRENCY_OPTION_LIST)}
                  {` ${confirmOrderInfo.omsOrder.payAmount.toLocaleString()}`}
                </span>
              </div>
            </Spin>
          )}
        </div>

        <div className="flex justify-center">
          <Modal
            title={i18n.t(LOCALS.MultiplePayList) || '设置多笔支付'}
            open={multiPayOpen}
            onCancel={toggleMultiPayOpen}
            footer={null}
            width={isMobile ? '100%' : '60%'}
          >
            {confirmOrderInfo && (
              <MultiplePaySet
                payAmount={confirmOrderInfo?.omsOrder.payAmountActualCurrency}
                currency={currency}
                onFinish={(list) => {
                  setPayload({
                    multiplePaySet: list.map((amount, index) => {
                      return {
                        sortId: index + 1,
                        needPayAmount: amount,
                        currency,
                      };
                    }),
                  });
                  toggleMultiPayOpen();
                }}
                onClear={() => {
                  setPayload({ multiplePaySet: undefined });
                  toggleMultiPayOpen();
                }}
              />
            )}
          </Modal>

          <Modal
            title={
              orderData ? (
                <Trans i18nKey={LOCALS.successful_operation} />
              ) : (
                <Trans i18nKey={LOCALS.confirm_submit} />
              )
            }
            open={modelOpen}
            onOk={handleCreateOrder}
            onCancel={() => {
              if (orderData) {
                window.location.reload();
              } else {
                toggleModelOpen();
              }
            }}
            confirmLoading={saveLoading}
            okButtonProps={{ disabled: !!orderData }}
          >
            {orderData ? (
              <>
                <div>
                  <Link to={`/oms/order-view/${orderData.id}`} target="_blank">
                    {i18n.t(LOCALS.to_order_details) || '跳转订单详情页'}
                  </Link>
                </div>
                <Paragraph copyable={{ tooltips: false }}>
                  {`${PORTAL_ORIGIN}/order?orderId=${orderData.id}`}
                </Paragraph>
              </>
            ) : (
              <></>
            )}
          </Modal>
          <Button
            disabled={
              !confirmOrderInfo ||
              omsOrderCreatePayload.orderStatus === ORDER_STATUS_MAP.COMPLETED
            }
            className="mr-2"
            onClick={toggleMultiPayOpen}
          >
            {i18n.t(LOCALS.MultiplePayList) || '设置多笔支付'}
          </Button>
          <Button
            onClick={() => {
              const {
                memberId,
                productIdList,
                createdFrom,
                receiveAddressId,
                orderStatus,
                deliveryType,
              } = omsOrderCreatePayload;
              if (!memberId) {
                message.warning(
                  `${i18n.t('please_select')}${i18n.t('member')}`
                );
                return;
              }
              if (
                deliveryType === DELIVERY_TYPE.DELIVERY &&
                !receiveAddressId &&
                orderStatus === ORDER_STATUS_MAP.TO_BE_PAID
              ) {
                message.warning(i18n.t('please_select_a_shipping_address'));
                return;
              }

              if (productIdList?.length === 0) {
                message.warning(i18n.t('select_items'));
                return;
              }

              if (createdFrom === undefined) {
                message.warning(i18n.t('enter_order_source'));
                return;
              }

              toggleModelOpen(true);
            }}
            type="primary"
            disabled={
              !omsOrderCreatePayload.memberId ||
              !omsOrderCreatePayload.productIdList.length ||
              omsOrderCreatePayload.orderStatus === undefined ||
              (omsOrderCreatePayload.orderStatus ===
                ORDER_STATUS_MAP.TO_BE_PAID &&
                !omsOrderCreatePayload.receiveAddressId &&
                omsOrderCreatePayload.deliveryType ===
                  DELIVERY_TYPE.DELIVERY) ||
              (omsOrderCreatePayload.deliveryType === DELIVERY_TYPE.PICKUP &&
                (!omsOrderCreatePayload.pickupTime ||
                  !omsOrderCreatePayload.receiverPhone))
            }
          >
            {i18n.t(LOCALS.create_order) || '创建订单'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderCreateV2;
