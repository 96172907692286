import dayjs from 'dayjs';

const getDatesAndWeekdays = (props?: {
  currentDate?: string;
  count?: number;
  offset?: number;
}) => {
  let currentDate = dayjs(props?.currentDate || new Date());
  // 偏移量
  currentDate = currentDate.add(props?.count || 5, 'day');
  const count = props?.count || 4;

  // Array to store results
  const datesAndWeekdays = [];

  // Weekdays array
  const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  // Loop to get dates and weekdays
  for (let i = 0; i < count; i++) {
    const futureDate = currentDate.add(i, 'day');
    const formattedDate = futureDate.format('YYYY-MM-DD');
    const dayOfWeek = weekdays[futureDate.day()];

    datesAndWeekdays.push({
      date: formattedDate,
      dayOfWeek,
      day: futureDate.day(),
    });
  }

  return datesAndWeekdays;
};

export default getDatesAndWeekdays;
