import { Button, Select, Spin } from 'antd';
import { getProductList } from 'apis/pms';
import { useMemo, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { PmsProduct } from 'types/pms';
import ProductList from './product-list';
import { thousands } from 'utils/tools';
import {
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import { OrderGenerateForMemberDto } from 'types/oms';
import i18n from '../../../i18n';
import LOCALS from '../../../commons/locals';
import { debounce } from 'lodash-es';

interface Props {
  setPayload: (data: Pick<OrderGenerateForMemberDto, 'productIdList'>) => void;
}

const ProductSelect = ({ setPayload }: Props) => {
  const [loading, toggleLoading] = useToggle(false);
  const [productList, setProductList] = useState<PmsProduct[]>([]);
  const [productOptionList, setProductOptionList] = useState<PmsProduct[]>([]);

  const handleSearch = useMemo(
    () =>
      debounce((keyword: string) => {
        keyword = keyword.trim();
        if (!keyword) {
          setProductOptionList([]);
          return;
        }

        toggleLoading(true);
        getProductList({
          keyword,
          pageNum: 1,
          pageSize: 10,
          transformPriceToJpyFlag: 0,
        })
          .then((data) => {
            setProductOptionList(data.data.list);
          })
          .catch()
          .finally(() => toggleLoading(false));
      }, 300),
    [toggleLoading]
  );

  useEffect(() => {
    setPayload({
      productIdList: productList.map(({ id }) => id),
    });
  }, [productList, setPayload]);

  return (
    <div className="p-4">
      <div className="flex justify-center items-center mb-4">
        <Select
          size="large"
          placeholder={i18n.t(LOCALS.sku_or_keywords) || '商品编号/名称'}
          className="w-full"
          filterOption={false}
          notFoundContent={loading ? <Spin size="small" /> : null}
          onSearch={handleSearch}
          value={null}
          showSearch
          onChange={(pmsProductId: PmsProduct['id']) => {
            const target = productOptionList.find((i) => i.id === pmsProductId);
            if (target && !productList.find((i) => i.id === target.id)) {
              setProductList([...productList, target]);
              setProductOptionList([]);
            }
          }}
          options={productOptionList.map((i) => {
            return {
              ...i,
              value: i.id,
              label: i.productSn,
            };
          })}
          // @ts-ignore
          optionRender={({
            data: {
              productSn,
              price,
              currency,
              stockPlace,
              publishStatus,
              stock,
            },
          }: {
            data: PmsProduct;
          }) => {
            return (
              <div>
                <span className="mr-2">{productSn}</span>
                <span className="mr-2">{`${currency} ${thousands(
                  price
                )}`}</span>
                <span className="mr-2">
                  {findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST)}
                </span>
                {findLabelByValue(publishStatus, PUBLISH_STATUS_OPTION_LIST)}
                {` / ${stock}`}
              </div>
            );
          }}
        />
        <Button
          onClick={() => {
            window.open('/pms/product-add', '_blank');
          }}
          size="large"
          className="ml-4"
          type="primary"
        >
          {i18n.t(LOCALS.add)}
        </Button>
      </div>

      <ProductList
        productList={productList}
        onDelete={(id) => {
          setProductList(productList.filter((i) => i.id !== id));
        }}
      />
    </div>
  );
};

export default ProductSelect;
