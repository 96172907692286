import { Descriptions, Tag } from 'antd';
import LOCALS from 'commons/locals';
import {
  findLabelByValue,
  DELIVERY_TYPE,
  DELIVERY_TYPE_OPTION_LIST,
  DELIVERY_TYPE_TAG_COLOR_MAP,
  ORDER_STATUS_MAP,
  CURRENCY_MAP,
} from 'commons/options';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { OmsOrderDetail } from 'types/oms';
import { useDescProps } from 'commons/hooks/useDescProps';
import ModifyDeliveerTypeModal from './modify-delivery-type';
import dayjs from 'dayjs';

type RecipientInformationProps = {
  omsOrderDetail: OmsOrderDetail;
};

const DeliveryInformation = ({
  omsOrderDetail: {
    receiverName,
    receiverPhone,
    receiverPostCode,
    receiverProvince,
    receiverCity,
    receiverDetailAddress,
    deliveryType = DELIVERY_TYPE.DELIVERY,
    pickupTime,
    deliveryCompany,
    deliverySn,
    status,
    memberId,
    id,
    shippingCost,
    deliveryTime,
    orderItemList,
  },
}: RecipientInformationProps) => {
  const [open, setOpen] = useState(false);

  const modifyBtn = useMemo(() => {
    if (status === ORDER_STATUS_MAP.TO_BE_DELIVERED)
      return (
        <a
          href="/"
          className="ml-2"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <Trans i18nKey={LOCALS.change_delivery_method} />
        </a>
      );
    return;
  }, [status]);

  return (
    <>
      <Descriptions
        title={
          <div className="flex items-center">
            <Trans i18nKey={LOCALS.deliveryInformation} />
          </div>
        }
        bordered
        {...useDescProps({})}
      >
        <Descriptions.Item
          label={<Trans i18nKey={LOCALS.delivery_method} />}
          span={3}
        >
          {deliveryType === DELIVERY_TYPE.PICKUP ? (
            <>
              <Tag color={DELIVERY_TYPE_TAG_COLOR_MAP[deliveryType]}>
                {findLabelByValue(deliveryType, DELIVERY_TYPE_OPTION_LIST)}
              </Tag>
              {modifyBtn}
            </>
          ) : (
            <>
              <Tag color={DELIVERY_TYPE_TAG_COLOR_MAP[deliveryType]}>
                {findLabelByValue(deliveryType, DELIVERY_TYPE_OPTION_LIST)}
              </Tag>
              {modifyBtn}
            </>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.shipment_number} />}>
          {deliveryCompany ? `${deliverySn} ${deliveryCompany}` : '-'}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.freight} />}>
          {shippingCost}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.shippingTime} />}>
          {deliveryTime
            ? dayjs(deliveryTime).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
        </Descriptions.Item>

        <Descriptions.Item
          label={<Trans i18nKey={LOCALS.contactInformation} />}
        >
          {receiverPhone}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.pickupProof} />}>
          -
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.pickupTime} />}>
          {pickupTime ? pickupTime : '-'}
        </Descriptions.Item>
      </Descriptions>

      {open && (
        <ModifyDeliveerTypeModal
          open={open}
          onCancel={() => setOpen(false)}
          data={{
            memberId,
            deliveryType,
            pickupTime,
            receiverCity,
            receiverProvince,
            receiverPostCode,
            receiverPhone,
            receiverName,
            receiverDetailAddress,
            id,
            currency:
              orderItemList && orderItemList.length
                ? orderItemList[0].actualCurrency
                : CURRENCY_MAP.JPY,
          }}
        />
      )}
    </>
  );
};

export default DeliveryInformation;
