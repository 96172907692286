import { Form, Input, Modal, Select, message, Radio } from 'antd';
import { getCityListByCountryCode } from 'apis/home';
import { MallCity } from 'types/home';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import i18n from 'i18n';
import LOCALS from 'commons/locals';
import { useState, useCallback, useEffect } from 'react';
import {
  DELIVERY_TYPE,
  TimePeriodList,
  DELIVERY_TYPE_OPTION_LIST,
  CURRENCY_MAP,
} from 'commons/options';
import getDatesAndWeekdays from 'utils/getDatesAndWeekdays';
import { omsUpdateDeliveryMethod } from 'apis/oms';
import { UpdateDeliveryMethod } from 'types/oms';

type Props = {
  open: boolean;
  onCancel: () => void;
  data: {
    id: number;
    memberId: number;
    deliveryType?: string;
    pickupTime?: string;
    receiverCity?: string;
    receiverProvince?: string;
    receiverPostCode?: string;
    receiverPhone?: string;
    receiverName?: string;
    receiverDetailAddress?: string;
    disabledDeliveryType?: boolean;
    payTime?: string;
    currency?: string;
  };
};

const ModifyDeliveerType = ({ open, onCancel, data }: Props) => {
  const [form] = Form.useForm();
  const { countryOptions } = useAppSelector(selectGlobalInfo);
  const [cityList, setCityList] = useState<MallCity[]>([]);

  const [deliveryType, setDeliveryType] = useState(data.deliveryType);
  const [deliveryTime, setDeliveryTime] = useState<[string, string] | []>([]);

  const getCityList = useCallback(
    (country: string) => {
      form.setFieldValue('city', '');
      setCityList([]);
      getCityListByCountryCode(country).then((res) => {
        setCityList(res.data.cityList || []);
      });
    },
    [form]
  );

  useEffect(() => {
    const {
      deliveryType,
      pickupTime,
      receiverCity,
      receiverProvince,
      receiverPostCode,
      receiverPhone,
      receiverName,
      receiverDetailAddress,
    } = data;
    const [date, time] = pickupTime ? pickupTime.split(' ') : ['', ''];

    const t = {
      deliveryType,
      date,
      time,
      receiverCity,
      receiverProvince,
      receiverPostCode,
      receiverPhone,
      receiverName,
      receiverDetailAddress,
    };
    form.setFieldsValue(t);
  }, [data, form]);

  useEffect(() => {
    if (data.receiverProvince) getCityList(data.receiverProvince);
  }, [data.receiverProvince, getCityList]);

  const onOk = () => {
    form.validateFields().then((values) => {
      const { deliveryType, date, time, ...rest } = values;
      let payload: UpdateDeliveryMethod = {
        orderId: data.id,
        deliveryType,
        ...rest,
      };
      if (deliveryType === DELIVERY_TYPE.PICKUP) {
        payload.pickupTime = `${date} ${time}`;
      }
      omsUpdateDeliveryMethod(payload).then(() => {
        message.success(i18n.t('successful_operation'));
        onCancel();
        window.location.reload();
      });
    });
  };

  return (
    <Modal
      open={open}
      title={
        data.disabledDeliveryType
          ? i18n.t('modify_recipient_information')
          : i18n.t('change_delivery_method')
      }
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} labelWrap>
        <Form.Item name="deliveryType">
          <Radio.Group
            onChange={(e) => {
              const val = e.target.value;
              setDeliveryType(val);
            }}
            value={deliveryType}
            disabled={data.disabledDeliveryType}
          >
            {DELIVERY_TYPE_OPTION_LIST.map((d) => (
              <Radio key={d.value} value={d.value}>
                {d.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {deliveryType === DELIVERY_TYPE.DELIVERY ? (
          <>
            <Form.Item
              label={i18n.t(LOCALS.name)}
              rules={[{ required: true }]}
              name="receiverName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n.t(LOCALS.phone_number)}
              rules={[{ required: true }]}
              name="receiverPhone"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n.t(LOCALS.postal_code)}
              name="receiverPostCode"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={i18n.t(LOCALS.country_region)}
              name="receiverProvince"
            >
              <Select
                onChange={(e) => {
                  form.setFieldValue('receiverCity', '');
                  getCityList(e);
                }}
                options={countryOptions}
                showSearch
                filterOption={(input: string, option?: any) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              ></Select>
            </Form.Item>
            <Form.Item
              label={i18n.t(LOCALS.state_province_city)}
              name="receiverCity"
            >
              <Select
                showSearch
                filterOption={(input: string, option?: any) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={cityList.map(({ code, name }) => {
                  return { value: code, label: name };
                })}
              ></Select>
            </Form.Item>
            <Form.Item
              label={i18n.t(LOCALS.detail_address)}
              name="receiverDetailAddress"
            >
              <Input.TextArea />
            </Form.Item>
          </>
        ) : (
          <div className="my-2">
            <div className="flex flex-col w-full">
              <Form.Item
                name="date"
                rules={[{ required: true }]}
                label={i18n.t(LOCALS.pickup_date) || ''}
              >
                <Select
                  className="w-full"
                  placeholder={i18n.t(LOCALS.pickup_date) || ''}
                  onChange={(data) =>
                    setDeliveryTime([data, deliveryTime[1] || ''])
                  }
                  options={getDatesAndWeekdays({
                    currentDate: data.payTime,
                    count: 7,
                  }).map(({ date, dayOfWeek, day }) => {
                    return {
                      value: date,
                      label: `${date} ${i18n.t(dayOfWeek as string)}`,
                      disabled: data.currency === CURRENCY_MAP.JPY && day === 0,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                name="time"
                rules={[{ required: true }]}
                label={i18n.t(LOCALS.pickup_time_slot) || ''}
              >
                <Select
                  className="w-full"
                  placeholder={i18n.t(LOCALS.pickup_time_slot) || ''}
                  onChange={(data) =>
                    setDeliveryTime([deliveryTime[0] || '', data])
                  }
                  options={TimePeriodList.map((d) => ({
                    value: d,
                    label: d,
                  }))}
                />
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default ModifyDeliveerType;
