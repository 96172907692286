import { Descriptions, Tag } from 'antd';
import LOCALS from 'commons/locals';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  findLabelByValue,
  DELIVERY_TYPE,
  DELIVERY_TYPE_TAG_COLOR_MAP,
  ORDER_STATUS_MAP,
} from 'commons/options';
import CopyButton from 'components/copy-button';
import LinkButton from 'components/link-button';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { OmsOrderDetail } from 'types/oms';
import formatTime from 'utils/formatTime';
import { useDescProps } from 'commons/hooks/useDescProps';

import i18n from 'i18n';
type OrderBaseInfoProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderBaseInfo = ({ omsOrderDetail }: OrderBaseInfoProps) => {
  const {
    status,
    orderSn,
    memberUsername,
    createdFrom,
    integration,
    memberId,
    multiplePayStatus,
    createTime,
    deliveryType = DELIVERY_TYPE.DELIVERY,
  } = omsOrderDetail;
  const { orderStatusOptions, orderTypeOptions } =
    useAppSelector(selectGlobalInfo);

  return (
    <>
      <Descriptions
        {...useDescProps({})}
        title={<Trans i18nKey={LOCALS.basic_info} />}
        bordered
      >
        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_status} />}>
          {deliveryType === DELIVERY_TYPE.PICKUP &&
          status === ORDER_STATUS_MAP.TO_BE_DELIVERED ? (
            <Tag color={DELIVERY_TYPE_TAG_COLOR_MAP[deliveryType]}>
              {i18n.t('pending_pickup')}
            </Tag>
          ) : (
            <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
              {findLabelByValue(status, orderStatusOptions)}
            </Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_sn} />}>
          {orderSn}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.created_time} />}>
          {formatTime(createTime)}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.member_account} />}>
          {memberId && memberUsername ? (
            <CopyButton copyText={memberUsername}>
              <LinkButton href={`/ums/member-view/${memberId}`}>
                {memberUsername}
              </LinkButton>
            </CopyButton>
          ) : (
            '-'
          )}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.created_from} />}>
          {findLabelByValue(createdFrom, ORDER_CREATED_FROM_OPTION_LIST)}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.points_for_order} />}>
          {integration || 0}
        </Descriptions.Item>
        <Descriptions.Item label={<Trans i18nKey={LOCALS.order_type} />}>
          {findLabelByValue(omsOrderDetail.orderType, orderTypeOptions)}
        </Descriptions.Item>
        <Descriptions.Item label={<Trans i18nKey={LOCALS.MultiplePayList} />}>
          {multiplePayStatus ? <Trans i18nKey={LOCALS.yes} /> : '-'}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default OrderBaseInfo;
